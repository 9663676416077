/** @jsx jsx */
import { jsx, Text } from 'theme-ui';

import { dateString, hourAndMinutes } from '../utils/date';

export default function Timestamp({ date, prepend = '' }) {
  return (
    <Text sx={{ display: 'inline' }} variant="dim">
      {prepend}
      {dateString(date)} {hourAndMinutes(date)}
    </Text>
  );
}
