function dateString(date) {
  const today = new Date().getDate();
  const yesterday = new Date().getDate() - 1;
  const theDate = new Date(date);
  const theDateDay = theDate.getDate();
  const options = {
    weekday: 'long',
  };

  if (theDateDay === today) {
    return 'idag';
  } else if (theDateDay === yesterday) {
    return 'igår';
  } else {
    return theDate.toLocaleDateString('sv-SE', options);
  }
}

function hourAndMinutes(date) {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(date).toLocaleTimeString('sv-SE', options);
}

module.exports = {
  dateString,
  hourAndMinutes,
};
