function getPath(item) {
  const url = new URL(item.url.replace(/\/$/, ''));
  const paths = url.pathname.split('/');
  const lastPath = paths[paths.length - 1];
  return [item.source.toLowerCase(), lastPath].join('/');
}
// function getPath(item) {
//   const url = new URL(item.url);
//   const date = new Date(item.date);

//   const paths = url.pathname.split('/');
//   const lastPath = paths[paths.length - 1];

//   return [date.getFullYear(), date.getMonth(), date.getDate(), lastPath].join(
//     '/',
//   );
// }

module.exports = {
  getPath,
};
