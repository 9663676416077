/** @jsx jsx */
import { jsx, Styled, Flex, Box } from 'theme-ui';

import { getPath } from '../utils/item';

import Layout from '../components/layout';
import Date from '../components/date';
import Timestamp from '../components/timestamp';
import Source from '../components/source';
import Link from '../components/link';

function Article(item) {
  const { title, source, prevSource } = item;
  const url = getPath(item);
  return (
    <article>
      {prevSource !== source && (
        <Flex sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Source source={source} />
        </Flex>
      )}
      <Flex
        sx={{
          alignItems: 'flex-start',
          flexDirection: 'row',
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <Styled.h3>
          <Link href={url} title={title} />
        </Styled.h3>
      </Flex>
    </article>
  );
}

function Day({ datum, items }) {
  let source;
  let prevSource;
  return (
    <section>
      <Flex
        sx={{
          justifyContent: 'center',
          flexDirection: 'row',
          marginTop: '2rem',
        }}
      >
        <Date date={datum} />
      </Flex>
      {items.map((item) => {
        prevSource = source;
        source = item.source;
        return <Article key={item.id} prevSource={prevSource} {...item} />;
      })}
    </section>
  );
}

export default function Home({ pageContext: { data, builtAt } }) {
  const { days, byDay } = data;
  return (
    <Layout>
      {days.map((datum) => (
        <Day key={datum} datum={datum} items={byDay[datum]} />
      ))}
      <Box sx={{ textAlign: 'right' }}>
        <Timestamp date={builtAt} prepend="Uppdaterad " />
      </Box>
    </Layout>
  );
}
