/** @jsx jsx */
import { jsx, Badge } from 'theme-ui';

export default function Source({ source }) {
  return (
    <Badge
      sx={{
        backgroundColor: source,
      }}
      mr={2}
    >
      {source}
    </Badge>
  );
}
